import _styled from "styled-components";
import { SupportedLlm, ChatMessageState, getKindoErrorMessage, isKindoErrorCode, LLM_DISPLAY_NAMES, TOOL_DISPLAY_NAMES, getFileTypeFromMimeType, ChatMessageContentType, FileType, Tool } from '@kindo/universal';
import { Button, ButtonType, Color, Icon, Icons, Size, Typography, TypographySize, TypographyWeight } from '../../core';
import { Markdown } from '../../Markdown';
import { ContentReference } from '../../shared/ContentReference';
import { CopyButton } from '../../shared/CopyButton';
import { ResponseChatMessage } from '../Chat.types';
import useSmoothStreaming from './useSmoothStreaming';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { CsvViewer } from '~/components/shared/CsvViewer';
import { useAppSelector, useSlackMessageSharing } from '~/hooks';

// For csv-row-by-row, enable tooltip for response and error columns on CSV output
// so that all text is visible
const CSV_COLUMNS_WITH_TOOLTIP = ['response', 'error'];
const ResponseChatMessageContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__ResponseChatMessageContainer",
  componentId: "sc-13ospnt-0"
})(({
  $isError,
  $isKindoError
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem"
}, $isError && {
  "gap": "1.5rem",
  "borderRadius": "0.375rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(248 113 113 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 202 202 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
}, $isKindoError && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 224 71 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 249 195 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
}, !$isError && {
  "borderLeftWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(29 78 216 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}]);
const ContentAndToolReferencesContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__ContentAndToolReferencesContainer",
  componentId: "sc-13ospnt-1"
})({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const CsvViewerContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__CsvViewerContainer",
  componentId: "sc-13ospnt-2"
})({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const CsvContentReferenceContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__CsvContentReferenceContainer",
  componentId: "sc-13ospnt-3"
})({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "alignItems": "center",
  "gap": "0.5rem"
});
const MessageTextContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__MessageTextContainer",
  componentId: "sc-13ospnt-4"
})({
  "display": "flex"
});
const ActionButtons = _styled.div.withConfig({
  displayName: "ResponseChatMessage__ActionButtons",
  componentId: "sc-13ospnt-5"
})({
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "paddingRight": "1rem",
  ".group\\/message:hover &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))"
  }
});
const HiddenButtons = _styled.div.withConfig({
  displayName: "ResponseChatMessage__HiddenButtons",
  componentId: "sc-13ospnt-6"
})({
  "visibility": "hidden",
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  ".group\\/message:hover &": {
    "visibility": "visible"
  }
});
const ModelNameAndMessageContainer = _styled.div.withConfig({
  displayName: "ResponseChatMessage__ModelNameAndMessageContainer",
  componentId: "sc-13ospnt-7"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
type ResponseChatMessageProps = {
  chatMessage: ResponseChatMessage;
  onRegenerate: () => void;
  onStreamChange: () => void;
};
const ResponseChatMessageComponent: React.FC<ResponseChatMessageProps> = ({
  chatMessage,
  onRegenerate,
  onStreamChange
}) => {
  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Custom Hooks
  const {
    isSlackConnected,
    slackChannels,
    handleShareToSlack
  } = useSlackMessageSharing();
  const {
    artifacts,
    isStreaming,
    message,
    model,
    referencedContent,
    state,
    workflowStepNumber
  } = chatMessage;
  const {
    text: smoothStreamingText
  } = useSmoothStreaming({
    chatMessage,
    onChange: onStreamChange
  });
  const isError = state === ChatMessageState.ERROR;
  const isKindoError = isError && isKindoErrorCode(message);
  const displayedMessage = isKindoError ? getKindoErrorMessage(message) : message;
  const toolsUsed = new Set(artifacts?.map(artifact => artifact.tool));
  // NOTE: Special case for handling the toolsUsed set when performing a library search.
  // Since vector search source nodes from the library are stored in a similar manner
  // to both specified content (current and historical) and the knowledge store,
  // we need to explicitly check for the presence of a LIBRARY_SEARCH ChatMessageContentType,
  // which indicates the use of the library search tool.
  if ((referencedContent?.filter(content => content.type === ChatMessageContentType.LIBRARY_SEARCH) ?? []).length > 0) {
    toolsUsed.add(Tool.LIBRARY_SEARCH);
  }
  const inputReferencedContent = referencedContent?.filter(content => content.type !== ChatMessageContentType.OUTPUT);
  const outputReferencedContent = referencedContent?.filter(content => content.type === ChatMessageContentType.OUTPUT && getFileTypeFromMimeType(content.mimeType ?? '') === FileType.CSV);
  return <ResponseChatMessageContainer $isError={isError} $isKindoError={isKindoError} className="group/message">
      {(!!inputReferencedContent?.length || !!artifacts?.length) && !chatMessage.batch && <ContentAndToolReferencesContainer>
            {inputReferencedContent?.map(content => <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} source={content.source} tooltip={content.sourceContent} />)}
            {artifacts?.map(artifact => <ContentReference key={artifact.contentId} canDownload={false} contentId={artifact.contentId} fileName={artifact.pageTitle} source={artifact.source} tooltip={artifact.sourceContent} url={artifact.url} />)}
          </ContentAndToolReferencesContainer>}

      <MessageTextContainer>
        <ModelNameAndMessageContainer>
          <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
            {LLM_DISPLAY_NAMES[(model ?? '') as SupportedLlm] || 'AI'}
            {toolsUsed.size > 0 && <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                {` | Tools used: ${[...toolsUsed].map(tool => TOOL_DISPLAY_NAMES[tool]).join(', ')}`}
              </Typography>}
          </Typography>
          {isStreaming && smoothStreamingText ? <Markdown>{smoothStreamingText}</Markdown> : <Markdown>{displayedMessage}</Markdown>}
        </ModelNameAndMessageContainer>
      </MessageTextContainer>
      {!isError && <ActionButtons>
          <HiddenButtons>
            <CopyButton size={Size.SMALL} text={message} />
            {/*
              Hide regenerate if it is a workflow step for now, to avoid
              revealing the underlying prompt
             */}
            {onRegenerate && workflowStepNumber === undefined && <Button label="Re-generate" onClick={() => onRegenerate?.()} size={Size.SMALL} startIcon={Icon.RELOAD} type={ButtonType.OUTLINED} />}
            {isSlackConnected && <Dropdown trigger={<Icons color={Color.NAVY} icon={Icon.SLACK} />}>
                {slackChannels.map(channel => <DropdownItem key={channel.id} onClick={() => handleShareToSlack(channel.id, displayedMessage)} title={`# ${channel.channelName}`} />)}
              </Dropdown>}
          </HiddenButtons>
        </ActionButtons>}
      {/** Output CSVs */}
      {outputReferencedContent?.map(content => <CsvViewerContainer key={content.contentId}>
          <CsvViewer columnsWithTooltip={CSV_COLUMNS_WITH_TOOLTIP} contentReference={content} />
          <CsvContentReferenceContainer>
            <Typography color={Color.NAVY} size={TypographySize.MEDIUM}>
              Output file:
            </Typography>
            <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} source={content.source} tooltip={content.sourceContent} />
          </CsvContentReferenceContainer>
        </CsvViewerContainer>)}
    </ResponseChatMessageContainer>;
};
export default ResponseChatMessageComponent;