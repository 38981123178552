import _styled from "styled-components";
import { Color, Typography, TypographySize, TypographyWeight } from '~/components/core';
const WorkstationTitleContainer = _styled.div.withConfig({
  displayName: "WorkstationTitle__WorkstationTitleContainer",
  componentId: "sc-6k3il2-0"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem",
  "borderBottomWidth": "1px",
  "borderColor": "#4d728038",
  "paddingBottom": "0.5rem",
  "paddingRight": "0.75rem",
  "paddingTop": "0.5rem"
});
interface WorkstationTitleProps {
  title: string;
  description?: string | undefined;
}
const WorkstationTitle: React.FC<WorkstationTitleProps> = ({
  title,
  description
}) => <WorkstationTitleContainer>
    <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_NORMAL}>
      {title}
    </Typography>
    {!!description && <Typography color={Color.GRAY}>{description}</Typography>}
  </WorkstationTitleContainer>;
export default WorkstationTitle;