import { SupportedLlm, ChatMessageContentType, ChatMessageRole, ChatMessageState, Tool } from '@kindo/universal';
import { ContentSource } from '../Library/components/FilesTab';
import { trpc } from '~/trpc';
import { PromptTemplateWorkflowStepInput } from '~/types';
export type ChatMessageItem = Awaited<ReturnType<(typeof trpc)['chats']['getChatMessages']['query']>>['items'][number];
export type Chat = Awaited<ReturnType<(typeof trpc)['chats']['createChatFromWorkflow']['mutate']>>['item'];
export type WorkflowInputValue = ChatMessageItem['workflowInputValues'][number];
export interface ChatMessageContentReference {
  contentId: string;
  creatorId: string;
  fileName: string;
  mimeType: string;
  referenceId: string;
  source: ContentSource;
  sourceContent: string | undefined;
  type: ChatMessageContentType;
}
export interface ChatMessageArtifactReference {
  contentId: string;
  pageTitle: string;
  source: ContentSource;
  sourceContent: string;
  tool: Tool;
  url: string;
}
export interface DisplayedChatMessageBatch {
  batchId: string;
  prompt: string;
}
export interface BaseDisplayedChatMessage {
  id: string;
  message: string;
  model: SupportedLlm;
  role: ChatMessageRole;
  state: ChatMessageState;
  artifacts?: ChatMessageArtifactReference[];
  batch?: DisplayedChatMessageBatch | undefined;
  referencedContent?: ChatMessageContentReference[];
  transformedMessage?: string | undefined;
  workflowInputValues?: WorkflowInputValue[];
  workflowStepNumber?: number | undefined;
}
export interface UserChatMessage extends BaseDisplayedChatMessage {
  displayName: string | undefined;
  role: ChatMessageRole.USER;
}
export interface ReadyUserChatMessage extends UserChatMessage {
  state: ChatMessageState.READY;
  userInputs: PromptTemplateWorkflowStepInput[];
}
export interface ResponseChatMessage extends BaseDisplayedChatMessage {
  isStreaming: boolean;
  role: ChatMessageRole.ASSISTANT;
}
export interface SystemChatMessage extends BaseDisplayedChatMessage {
  role: ChatMessageRole.SYSTEM;
}

// TODO: Move this to the API as a transport response model
export type DisplayedChatMessage = UserChatMessage | SystemChatMessage | ReadyUserChatMessage | ResponseChatMessage;
export const isResponseChatMessage = (message: BaseDisplayedChatMessage): message is ResponseChatMessage => message.role === ChatMessageRole.ASSISTANT;
export const isUserChatMessage = (message: BaseDisplayedChatMessage): message is UserChatMessage => message.role === ChatMessageRole.USER;
export const isReadyUserChatMessage = (message: BaseDisplayedChatMessage): message is ReadyUserChatMessage => isUserChatMessage(message) && message.state === ChatMessageState.READY;
export type DisplayedChat = {
  messages: DisplayedChatMessage[];
  referenceCount: number;
};